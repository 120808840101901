import * as React from 'react'
import {Component} from 'react'
import {Badge, Col, Icon, List, Modal, Row, Switch} from "antd-min";
import {connect, GLAction, GLGlobal, PaginationParams, withRouter} from "gl-commonui";
import {GSAdminLocale} from '../../locales/localeid';
import {
    fmtMsg,
    GSAdminAction,
    isHtml,
    lazyInject,
    NotificationChannel,
    parseUrlToAnchor,
    TYPES
} from '../../util/index';
import {Link, RouteComponentProps} from 'react-router-dom';
import {PathConfig} from '../../config/pathconfig';
import {INotificationService, NotificationModel, NotificationStatus} from '../../service/notifications/index';
import {NotificationDescription, NotificationTitleItem} from './components';
import {StateType} from "@app/states";
import {getNumUnread} from "@app/states/notification";

interface NotificationsProps {
    numUnread: number,
    getNumUnread: () => void,
}
interface NotificationsStates {
    list: NotificationModel[]
    loading: boolean
    reviewTotal: number
    hideArchived: boolean
}

@withRouter
@connect(
    ({ notification: { numUnread } }: StateType) => ({ numUnread }), { getNumUnread }
)
export class NotificationsPage extends Component<NotificationsProps & RouteComponentProps<any>, NotificationsStates> {
    @lazyInject(TYPES.INotificationService)
    service: INotificationService
    pagination: PaginationParams = new PaginationParams(1, 20)
    constructor(props, context) {
        super(props, context);
        this.state = {
            list: [],
            loading: false,
            reviewTotal: 0,
            hideArchived: true,
        }
    }
    componentDidMount() {
        this.getNotifications();
        this.service.getItemsBy({ status: NotificationStatus.Submitted, offset: 0, limit: 0 }).then(d => this.setState({ reviewTotal: d.totalCount }))
    }

    getNotifications(current?) {
        this.pagination.current = current || this.pagination.current;
        this.setState({loading: true});
        this.service.getItemsBy({
            orderBy: 'sticky',
            hideArchived: this.state.hideArchived,
            channel: [NotificationChannel.BulletinBoard, NotificationChannel.BulletinBoardAndEmail], ...this.pagination.toRequest()
        }).then(d => {
            this.pagination.total = d.totalCount;
            this.setState({
                list: d.data.map(d => {
                    d.title = parseUrlToAnchor(d.title);
                    d.description = isHtml(d.description) ? d.description : parseUrlToAnchor(d.description);
                    return d;
                }), loading: false
            });
        })
    }

    renderListItem({ title, description, id, sticky, archived, startDate, seen }) {
        return (
            <List.Item>
                <List.Item.Meta
                    title={
                        <NotificationTitleItem
                            item = {{id, title, startDate, description, sticky, archived, seen}}
                            hasPopover = {false}
                            onMarkOneAsRead = {(id) => {
                                !seen && this.service.markAsReadForOne(id).then(() => {
                                    this.props.getNumUnread();
                                    this.getNotifications();
                                });
                            }}
                            onClearOne = {(id) => {
                                this.service.createArchived(id).then(() => {
                                    this.props.getNumUnread();
                                    this.getNotifications();
                                })
                            }}
                        />
                    }
                    description={<NotificationDescription content={description} />}
                />
            </List.Item>
        );
    }
    clickMarkAllAsRead() {
        this.service.markAsReadForAll().then(() => {
            this.props.getNumUnread();
            this.getNotifications();
        });
    }
    clickClearAll() {
        this.service.markAsArchived().then(() => {
            this.props.getNumUnread();
            this.getNotifications();
        });
    }
    render() {
        const { list, loading, reviewTotal } = this.state;
        const { numUnread } = this.props;
        return (
            <div className='notifications content-layout'>
                <Row type="flex" justify="space-between" className="notifications__top">
                    <Col className="notifications__top__tabs">
                        <div className="notifications__top__tabs__btn notifications__top__tabs__btn--active">
                            <Badge count={numUnread} offset={[-10, 10]}>
                                <span style={{display: 'inline-block'}}>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationCreateNotifications })}</span>
                            </Badge>
                        </div>
                        <GLAction action={GSAdminAction.CreateNotification}>
                            <div className="notifications__top__tabs__btn">
                                <Link to={PathConfig.NotificationCreated}>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationManageNotifications })}</Link>
                            </div>
                        </GLAction>
                    </Col>
                    <Col className="notifications__top__links">
                        <p><a href="javascript:void(0)" onClick={() => this.clickMarkAllAsRead()}>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationMarkAllAsRead })}<Icon type="check" /></a></p>
                        <p><a href="javascript:void(0)" onClick={() => this.clickClearAll()}>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationClearAll })}<Icon type="arrow-right" /></a></p>
                    </Col>
                </Row>
                <div className="page-content">
                    <div>
                        <div className="switch-cleared-notifications">
                            <Switch defaultChecked={!this.state.hideArchived} onChange={(e) => {
                                this.setState({ hideArchived: !e }, () => {
                                    this.getNotifications(1);
                                })
                            }} />
                            {fmtMsg(GSAdminLocale.NotificationsCleared)}
                        </div>
                        <div>
                            <GLAction action={GSAdminAction.CreateNotification}>
                                <Link to={PathConfig.NotificationCreate}><Icon type="plus-circle-o" />{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationCreateAdd })}</Link>
                            </GLAction>
                        </div>
                    </div>
                    <List dataSource={list} locale={{ emptyText: GLGlobal.intl.formatMessage({ id: GSAdminLocale.NotificationsNoData }) }} loading={loading} pagination={list.length > 0 ? {
                        ...this.pagination,
                        onChange: page => this.getNotifications(page),
                    } : null} renderItem={this.renderListItem.bind(this)}></List>
                </div>
            </div>
        )
    }
}
